<template>
  <section class="section-block">
    <SectionTitle @edit="modal.edit = true">
      <template #title>
          <div class="flex items-center gap-[8px]">
            <p class="font-medium" style="font-size: 24px">審查連結設定</p>
            <TipInfo>
              <p>此連結為方便店家進行金流審查之目的，不建議用於店家營運。<br>
                若想了解詳細資訊，請洽小幫手為您服務。</p>
            </TipInfo>
          </div>
        </template>
    </SectionTitle>
    <BaseElForm label-position="left" label-width="200px">
      <BaseElFormItem label="店家名稱">
        <div class="secret-text collapse">
          <span>{{ displayData.name }}</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="地址">
        <div class="secret-text collapse">
          <span>{{ displayData.address }}</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="電話">
        <div class="secret-text collapse">
          <span>{{ displayData.phone }}</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="統編">
        <div class="secret-text collapse">
          <span>{{ displayData.shopIdentifier }}</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="商城公開頁連結">
        <div class="copy-block">
            <a class="secret-text">
              {{ displayData.publicPageLink }}
            </a>
            <div>
              <BaseElButton
                class="copy-btn"
                type="primary"
                :disabled="displayData.publicPageLink === '尚未設定'"
                @click.prevent="copyLink(displayData.publicPageLink)"
              >
                複製
              </BaseElButton>
            </div>
          </div>
      </BaseElFormItem>
    </BaseElForm>

    <MemberShopPublicPageConfigEditModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, ref, computed, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import MemberShopPublicPageConfigEditModal from './MemberShopPublicPageConfigEditModal.vue'
import TipInfo from '@/components/TipInfo.vue'
import copy from 'clipboard-copy'

export default defineComponent({
  name: 'MemberShopPublicPageConfigBlock',
  components: { SectionTitle, MemberShopPublicPageConfigEditModal, TipInfo },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props) {
    const displayData = computed(() => {
      const config = props.configData
      return {
        name: config.name || '尚未設定',
        address: config.address || '尚未設定',
        phone: config.phone || '尚未設定',
        shopIdentifier: config.shopIdentifier || '尚未設定',
        publicPageLink: config.publicPageLink || '尚未設定',
      }
    })
    const copyLink = (link) => {
      copy(link)
      window.$message.success('已成功複製連結！')
    }
    const modal = reactive({
      edit: false,
    })
    return { displayData, modal, copyLink }
  },
})
</script>

<style lang="postcss" scoped>
.copy-block {
  @apply flex items-center overflow-hidden max-w-[440px];

  a {
    @apply overflow-hidden text-gray-80;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
